.Recent_recentContainer__2JZnD {
  display: flex;
  flex-direction: column;
  height: calc(100%);
  width: 100%;
  overflow: hidden auto;
  flex: 0 1 100%;
}

.Recent_segmentContainer__134FC {
  padding: 11px;
}

.Recent_activeSegmentContainer__2aMti {
  flex: 0 1 100%;
  height: 100%;
}

.Recent_segmentItem__lZOHH {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Recent_noEvents__PKL0D {
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.Recent_noEvents__PKL0D.Recent_visible__2gAox.Recent_transition__Dxv5f {
  display: flex !important;
}