.Upcoming_upcomingContainer__2-3R6 {
  display: flex;
  flex-direction: column;
  height: calc(100%);
  width: 100%;
  overflow: hidden auto;
  flex: 0 1 100%;
}

.Upcoming_segmentContainer__1aBJ8 {
  padding: 11px;
}

.Upcoming_activeSegmentContainer__1XtR7 {
  flex: 0 1 100%;
  height: 100%;
}

.Upcoming_segmentItem__2GgpX {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Upcoming_noEvents__MCCpu {
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.Upcoming_noEvents__MCCpu.Upcoming_visible__mFPFz.Upcoming_transition__1JFYm {
  display: flex !important;
}