.KeySegmentsCount_keySegmentsLabel__KBqPv {
  font-size: 1.2em;
  line-height: 1.15em;
  font-weight: 700;
  text-align: left;
  text-decoration: underline;
}

.KeySegmentsCount_keySegmentsCountValue__J5Qfj {
  font-size: 1.4em;
  line-height: 1.15em;
  text-align: right;
}

.KeySegmentsCount_keySegmentsRow__3FnaV {
  width: 100%;
  margin: 0 0 0 0.75em;
  padding: 4px 5px 3px 0px;
  border-bottom: 1px solid #D8E2EA;
}