.prof-kpigroup_listOfTargetsCol__2k6Pt {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: flex-end;
}

.prof-kpigroup_styledProfValue__1gTGJ {
  color: #005cab;
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 400;
  text-align: center;
}

.prof-kpigroup_styledProfDetailName__2s_lw {
  color: #005cab;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  line-height: 1em;
}

.prof-kpigroup_styledProfValueLabel__2_F1r {
  font-weight: normal;
  font-size: 1em;
  text-align: center;
  color: #595959;
}

.prof-kpigroup_stateRestrictionValue__2FAlP {
  color: #DB2828;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

.prof-kpigroup_stateRestrictionLabel__2pTAJ {
  color: #DB2828;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.prof-kpigroup_styledTableText__21P7- {
  font-size: 1rem;
  color: #595959;
  text-align: center;
}

.prof-kpigroup_salesTargetTableHeader__2F3Gg {
  width: 100%;
  font-weight: 600;
  font-size: 1.28571429rem;
  text-align: center;
  color: #595959;
  padding: 0px 0px 5px 0px;
}

.prof-kpigroup_profCard360Button__3_V_9 {
  background-color: transparent;
  font-weight: bold;
  padding: 8px;
  margin: 4px;
  border-color: #21BA45;
  color: #21BA45;
  border-radius: 3px;
  font-size: 0.8em;
  max-height: 21px;
  line-height: 0.6em;
  width: 50%;
}

.prof-kpigroup_salesTargetTable___0Xrh {
  padding: 5px 10px 10px 10px;
  margin-left: auto;
  margin-right: auto;
}

.prof-kpigroup_actionButton__3FT3b {
  font-size: 0.85714286rem;
  max-height: 20px;
  font-weight: bold;
  line-height: 1em;
  background-color: #005cab;
  border-color: #005cab;
  color: white;
  float: right;
  padding: 0.4rem;
  position: relative;
  top: -1.8rem;
}

.prof-kpigroup_editHCPDetailsButton__3lKh7 {
  font-size: 1rem;
  background-color: #005cab;
  border-color: #005cab;
  color: white;
  font-weight: bold;
  width: 100%;
  text-align: left;
}