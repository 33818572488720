.PreCallPlanning_subHead__2t3fj {
  font-weight: bold;
}

.PreCallPlanning_dataTable_header__ueyHt {
  font-weight: bold;
  font-size: 17px;
  color: #808080;
}

.PreCallPlanning_dataTable_full__2zCgS {
  margin-top: 8px;
  width: 100%;
}

.PreCallPlanning_dataTable_half__2Vd7Z {
  width: 48%;
}

.PreCallPlanning_bioName__2etsG {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}

.PreCallPlanning_profImage__zbMXM {
  height: 100%;
  min-height: 125px;
  max-height: 150px;
  max-width: 170px;
  margin: 0px;
  margin-right: 12px;
}

.PreCallPlanning_preCallPlanning_table__3pqgx {
  margin-top: 8px;
}

.PreCallPlanning_normalBox__1ZObh {
  height: 100%;
  min-height: 150px;
  border: solid 1px #E2E5E9;
  padding: 4px 8px;
  margin: 0px 8px 0px 0px;
}

.PreCallPlanning_normalBox_noBorder__14a3S {
  height: 100%;
  min-height: 150px;
  margin: 0px 8px 0px 0px;
}

.PreCallPlanning_targetBox__FHc9k {
  min-height: 150px;
  height: 100%;
  min-width: 225px;
  border: solid 1px #E2E5E9;
  padding: 0px;
}

.PreCallPlanning_targetBoxHead__288Zh {
  font-weight: bold;
  width: 100%;
  text-align: center;
  background-color: #bedefb;
  color: #000;
}

.PreCallPlanning_targetBoxTable__1W4N6 {
  text-align: center;
  height: 48%;
  margin-top: 1%;
}

.PreCallPlanning_targetBoxTable__1W4N6 {
  text-align: center;
}